/**
 * Single Form Main
 */
.single-form-main {
  background-color: #f2f2f2;
  max-width: 100% !important;
  min-height: 1050px;
  color: #000000a3;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-9.form-title-grid {
  margin-left: 57px;
}

.MuiFormControl-root.MuiTextField-root.form-title {
  width: 91.65%;
}

.form-description-wrapper-div {
  display: table;
  width: 91.65%;
}

#section-title {
  height: 31px;
}

.MuiAppBar-positionRelative {
  color: black !important;
  background: #fff !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

.main-appbar-center {
  text-align: center;
}

.main-appbar-right {
  float: right;
}

.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
  padding: 0px !important;
}

.paper-left-toolbar {
  position: fixed;
  top: 40%;
  width: 56px;
  height: 216px;
  color: #0000008a;
  z-index: 1;
}

.paper-left-toolbar.fieldTypes {
  height: 465px;
}

.MuiIconButton-root.icon-left-toolbar {
  padding: 9px !important;
  margin-left: 8px !important;
  margin-top: 1px !important;
}

.MuiIconButton-root.icon-left-toolbar.fields {
  padding: 6px !important;
  margin-left: 11px !important;
  margin-top: 1px !important;
}

.MuiIconButton-root.icon-left-toolbar.cancel {
  margin-left: 3px !important;
}

.MuiSvgIcon-root.addField-toolbar-cancel {
  color: #0000003b;
}

/**
 * Text Fields
 */
/*.form-title {
  width: 100%;
}

input#form-title {
  font-size: 34px;
}

input#form-title:focus + .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  display: none;
}*/

/**
 * Form Table view
 */
button.add__btn {
  border: none;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.field-type-selected {
  color: #000000a3;
  background-color: #ffffff;
  text-transform: none;
  width: 100%;
  height: 56px;
}

.MuiDivider-root.divider-vertical-menu.MuiDivider-flexItem.MuiDivider-vertical {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 0;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
  padding: 0;
}

.MuiSvgIcon-root.field-type-select-end-icon {
  font-size: 25px !important;
}

.MuiMenuItem-root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px !important;
}

.field-type-menu-left,
.field-type-menu-center,
.field-type-menu-right {
  display: inline-block;
  vertical-align: Top;
  color: #000000a3;
}

.field-type-menu-left,
.field-type-menu-right {
  width: 185px;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root {
  line-height: 35px;
}

/* field type select menu icon and label */
li > span {
  padding-right: 6px;
}

/* field type title and exapnd collapse icons */
.MuiButtonBase-root.MuiIconButton-root.field-arrow-icon-show {
  margin: 0px 5px;
  font-size: 20px;
}

.MuiButtonBase-root.MuiIconButton-root.field-arrow-icon-hidden {
  display: none;
}

.field-type-title-icon {
  display: table-cell;
  background: #fff;
  height: 56px;
}

/*#field-type-title {
  height: 42px;
  background: #fff;
  color: #000000a3;
}*/

.MuiFormControl-root.MuiTextField-root.textInputBasic-field-type-title.MuiFormControl-fullWidth {
  display: table-cell;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  /* font-size: 13px; */
  color: #000000a3;
}

/* field type options icon*/
.MuiGrid-root.field-container-grid-options.MuiGrid-item.MuiGrid-grid-sm-1 {
  display: flex;
  align-items: center;
}
.MuiButtonBase-root.MuiIconButton-root.field-type-options-btn {
  padding: 8px;
  margin-left: 8px;
}

.MuiDivider-root.fieldType-options-divider_1 {
  display: inline-block;
  border-left: 3px solid deepskyblue;
  height: 56px !important;
  margin-left: 24px;
  margin-right: 24px;
}

/* numeric type */
#numeric-cond-field:focus {
  border: none;
}

#numeric-cond-field {
  font-size: 13px;
  color: #000000a3;
}

.numeric-cond-field:hover {
  border: 1px solid #3f51b5;
}
