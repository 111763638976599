.development-fab-container {
    position: fixed;
    bottom: 16px;
    left: 50%;
    opacity: 0.75;
    z-index: 1;
}

.development-fab {
    background-color: white;
    border: 1px solid red !important;
}

.development-fab-icon {
    padding-left: 2px;
    color: red;
}

.development-title-text {
    font-size: 12px;
    color: yellow;
    border-bottom: 1px solid yellow;
}

.development-content-text {
    font-size: 10px;
    color: yellow;
    white-space: break-word;
}