.sa-container {
    width: 560px;
    overflow-x: hidden;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 44px;
}

.sa-container-2 {
    width: 560px;
    overflow-x: hidden;
    padding-left: 16px;
    padding-right: 16px;
}

.sa-no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.sa-section {
    padding-bottom: 8px;
}

.sa-top-padding {
    padding-top: 16px;
}

.sa-heading {
    font-size: 16px;
    color: #181F27;
    font-weight: 500;
    letter-spacing: 0.15px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.sa-breadcrumb-container {
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0,0,0,0.12);
}

.sa-spec-paragraph {
    background-color: #F6F9FE;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
}

.sa-breadcrumb-container {
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0,0,0,0.12);
}

.sa-spec-paragraph {
    background-color: #F6F9FE;
    padding: 8px;
    font-size: 14px;
}

.sa-indent-section {
    padding-left: 16px;
    padding-right: 16px;
}

.sa-indent {
    padding-left: 16px;
    margin-right: 0px;
    border-left: 1px solid rgba(0,0,0,0.12);
}

.sa-text12 {
    font-size: 12px;
    color: rgba(0,0,0,0.6);
}

.sa-text10 {
    font-size: 10px;
    color: rgba(0,0,0,0.6);
}

.sa-product-container {
    display: flex;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 8px;
    background-color: #FFFFFF;
    padding: 16px;
    margin-bottom: 8px;
}

.sa-product-left {
    padding-right: 12px;
}

.sa-product-link {
    padding-top: 8px;
    color: blue;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sa-product-select-tab-container {
    background-color: white !important;
    z-index: 2;
}

.sa-product-select-container {
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    border-bottom: 1px solid rgba(0,0,0,0.12);
}

.sa-product-select-left {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sa-product-select-right {
    flex: 1;
    text-align: right;
}

.sa-product-text-title {
    width: 275px;
    max-width: 275px;
    font-size: 14px;
    letter-spacing: 0.15px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sa-product-text-id {
    padding-left: 8px;
    width: 125px;
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 20px;
    color: rgba(0,0,0,0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sa-conditional-container {
    display: flex;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 8px;
    background-color: #FFFFFF;
    padding: 16px;
    margin-bottom: 8px;
}

.sa-conditional-left {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sa-conditional-right {
    color: blue
}

.sa-footer-container {
    position: fixed;
    width: 560px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    bottom: 0;
    right: 0;
    height: 56px;
    line-height: 56px;
    border-top: 1px solid rgba(0,0,0,0.12);
}

.sa-button-left {
    float: left;
}

.sa-button-right {
    float: right;
}

.sa-button {
    font-size: 12px;
    text-transform: none;
}

.sa-link {
    color: blue;
    text-decoration: none;
}

.sa-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.sa-icon:hover {
    cursor: pointer;
}

.sa-spec-container {
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 8px;
    background-color: #FFFFFF;
    margin-bottom: 8px;
    padding-bottom: 8px;
}

.sa-spec-left {
    width: 398px;
    padding-top: 16px;
    padding-left: 16px;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sa-spec-right {
    padding-top: 16px;
    padding-right: 16px;
    text-align: right;
    color: blue
}

.sa-spec-chip-container {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 8px;
}

.sa-spec-chip {
    display: inline-block;
    padding-right: 4px;
    padding-bottom: 4px;
    white-space: nowrap;
}

.sa-spec-chip:hover {
    cursor: pointer;
}

/* DrawerRow.js */
.drawer-row-container {
    width: 532px;
    height: 72px;
}

.drawer-row-left {
    float: left;
    width: 100px;
    height: 56px;
    margin-top: 8px;
    background-color: grey;
}

.drawer-row-middle {
    float: left;
    height: 72px;
    padding-top: 12px;
    padding-left: 16px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.drawer-row-title {
    color: #181F27;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 22px;
}

.drawer-row-description {
    color: rgba(0,0,0,0.6);
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
}

.drawer-row-right {
    text-align: right;
    height: 72px;
    padding-top: 24px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.drawer-title-container {
    height: 64px;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
}

.drawer-title-left {
    width: 50px;
    text-align: left;
    line-height: 64px;
    float: left
}

.drawer-title-title {
    text-align: left;
    line-height: 64px;
    float: left;
    font-size: 20px;
    letter-spacing: 0.24px;
    font-weight: bold;
}

.drawer-title-right {
    float: right;
    text-align: right;
    line-height: 64px;
}

.drawer-title-right-icon {
    font-size: 28px;
    color: rgba(0,0,0,0.6);
}

/* DrawerIssueSelect stuff */
.issue-footer-container {
    position: fixed;
    width: 560px;
    padding-left: 16px;
    background-color: white;
    bottom: 0;
    height: 56px;
    border-top: 1px solid rgba(0,0,0,0.12);
}

.issue-footer-button {
    padding-right: 16px;
    line-height: 56px;
}

#textfield-no-border {
    border: none;
}