@import './fonts_and_colors.scss';

/* General Universal Styles */
input:focus,
input.form-control:focus {
  outline: none;
  box-shadow: none;
  //border: 1px solid $blue;
}

.bold {
  font-family: $heavy;
}

table {
  width: 100%;
}

.noPadding {
  padding: 0 !important;
}

#app {
  font-family: $regular;
  //background-color: $bg-gray;
  background-color: #FFFFFF;
  min-height: 100vh;
  padding: 0;
  color: $dark-grey;
}

/* FOR NOW */
$breakpoint: 1160px;

/* Used in tables to prevent wrapping and weird overlapping */
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
 * Used in the SplashScreen
 */
.h-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

/*
 * --- Added for Ripple Admin ---
 */
.LoadingOverlay {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .LoadingOverlayContentContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 128px;
    height: 128px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .LoadingOverlayMessage {
    max-width: 256px;
    padding: 12px;
    text-align: center;
  }
}

.hide {
  display: none !important;
}

.row {
  margin-bottom: 15px;
}

ul.no-bullet {
  list-style-type: none;
  padding: 0;
}

/*   Modals   */
.ModalOverlay {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .ModalOverlayContentContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 85%;
    max-width: 50%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }

  .ModalOverlayContent {
    flex: 1;
    position: relative;
    margin: 8px 12px;
    overflow: scroll;
  }

  .ModalOverlayTopBar {
    flex: 0;
    cursor: default;
    padding: 4px;
    background-color: $dark-grey;
    position: relative;

    .ModalOverlayTitle {
      font-size: 16px;
      padding-left: 12px;
      color: $white;
    }

    .ModalOverlayCloseButton {
      position: absolute;
      top: 0;
      right: 1px;
      width: 32px;
      text-align: right;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .fas {
        color: $white;
      }
    }
  }
}

.ReactModal__Overlay {
  .ReactModal__Content {
    position: relative;
    inset: 0 !important;
    width: 75vw;
    overflow-x: hidden !important;
    overflow-y: hidden !important;

    .modal__header {
      font-family: $regular;
      font-size: 16px;
      margin-bottom: 16px;

      .modal__close {
        position: absolute;
        top: 8px;
        right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .modal__body {
      overflow-y: auto;
      max-height: 75vh;
      min-height: 75vh;

      .checkbox__override--wrapper {
        position: relative;
        display: flex;
        margin-left: 16px;

        .checkbox__style {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 200;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          pointer-events: none;

          &::before {
            content: '';
            display: block;
            margin: 4px;
            width: 18px;
            height: 18px;
            background-image: url('/images/lock.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }

        input.checkbox__override {
          opacity: 0;
          width: 28px;
          height: 28px;
        }

        input:checked + .checkbox__style {
          background-color: transparent !important;

          &::before {
            background-image: url('/images/lock-open-variant.svg');
          }
        }
      }

      .slider--wrapper {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;

        &:before {
          content: 'ON';
          position: absolute;
          top: 10px;
          left: 8px;
          font-family: $light;
          font-size: 10px;
          color: $white;
          z-index: 100;
        }
      }

      /* Hide default HTML checkbox */
      .slider--wrapper input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider__circle {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 34px;
        background-color: $black-38;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider__circle:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        border-radius: 50%;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider__circle:after {
        content: 'OFF';
        position: absolute;
        top: 10px;
        right: 8px;
        font-family: $light;
        font-size: 10px;
        color: $white;
        z-index: 100;
      }

      input:checked + .slider__circle {
        background-color: $blue;
      }

      input:checked + .slider__circle:before {
        -webkit-transform: translateX(26px) !important;
        -ms-transform: translateX(26px) !important;
        transform: translateX(26px) !important;
      }

      input:disabled + .slider__circle {
        background-color: $black-12;
      }
    }

    .form-group.row {
      display: flex;
      align-items: top;
      flex-wrap: nowrap;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: 0;

      .col-sm-10 {
        padding-right: 0;
      }
    }

    .row__override {
      display: flex;
      flex: 1;
      align-items: center;
    }

    label {
      font-family: $regular;
      margin-bottom: 0;
      width: 25%;
    }

    input {
      font-family: $light;
      padding: 0 12px;
    }

    input.select-search__input {
      padding-right: 48px;
    }

    .select-search__options {
      max-height: 20vh !important;
      padding: 0 !important;
    }

    .select-search.error input {
      border: 1px solid red;
    }

    .form-control.error {
      border: 1px solid $btn-red;
    }
  }
}

/*   Dashboard   */
.CompanyDashboard {
  .columns__two {
    -moz-column-count: 2;
    column-count: 2;

    @media (max-width: $mobile-size) {
      -moz-column-count: 1;
      column-count: 1;
    }
  }
  .actionsTable__wrapper {
    break-inside: avoid-column;
    padding: 16px;

    h2 {
      margin-bottom: 12px;
    }

    .actionsTable__header {
      background-color: $white;

      th {
        padding: 12px 0;

        &:first-child {
          padding-left: 6px;
        }
      }
    }
  }
}

.StatusOverlay {
  position: fixed;
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.5);

  .StatusOverlayContentContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }

  .StatusOverlayMessage {
    max-width: 256px;
    overflow: hidden;
    height: 100%;
    padding: 2px;
    margin-right: 6px;
    text-align: center;
    background: white;
    border: 1px solid blue;
    display: inline-block;
    font-size: 9pt;

    :last-child {
      margin-right: 0;
    }
  }

  .error {
    border-color: red;
    border-left-width: 4px;
  }
}

.LogoMark {
  img {
    width: 108px;
    padding-left: 10px;
  }

  img:first-child {
    padding-left: 0;
  }
}

.HeaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  background-color: $navy;
  color: $pale-navy;
  z-index: 1000;
  box-shadow: 4px 0 10px $black-38;
  padding: 0 !important;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  @media (max-width: $mobile-size) {
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
  }

  .Logo {
    flex-grow: 0;
    display: block;
    margin: 0 25px 16px;

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 16px auto;
    }

    .logo__mobile {
      display: none;
    }

    @media (max-width: $mobile-size) {
      max-width: 150px;
      margin-bottom: 0;

      .logo__mobile {
        display: block;
      }

      .logo__desktop {
        display: none;
      }
    }
  }

  /*   Side-Bar Nav   */
  .menu__desktop {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .Middle {
      flex-grow: 1;
      display: block;

      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        margin: 0 12px;
        padding: 4px 13px;
        background-color: transparent;

        &:hover {
          color: $white !important;
        }
      }

      ul {
        display: block;

        li {
          display: block;

          a {
            color: $pale-navy;
            padding: 4px 25px;

            &:hover {
              text-decoration: none;
              color: $white;
            }
          }
        }

        .dropdown-menu {
          position: relative !important;
          float: none !important;
          transform: none !important;
          border: none;
          background-color: transparent;
          margin-left: 16px;
        }

        .dropdown-item {
          white-space: normal;
          padding: 0 0 0 1.5rem;

          a {
            display: block;
            padding-left: 0;
          }
        }

        .dropdown-item:focus,
        .dropdown-item:hover {
          background-color: transparent;
          color: $blue !important;
        }

        .dropdown-toggle::after {
          border-top: 5px solid;
          border-right: 5px solid transparent;
          border-bottom: 0;
          border-left: 5px solid transparent;
        }
      }
    }

    .Revision {
      color: $AT-blue;
      font-size: 80%;
      font-style: italic;
      text-align: center;
    }

    .su-header-links {
      color: $AT-blue;
      font-size: 80%;
      font-style: italic;
      text-align: center;
    }

    .Right {
      display: block;
      margin: 0 16px;
      border-top: 1px solid $black-12;
      text-align: center;

      button {
        border: none;
        color: $white;
        width: 100%;
        padding: 12px 0 16px;

        &:hover {
          background-color: transparent;
          color: $blue;
        }
      }
    }

    @media (max-width: $mobile-size) {
      display: none;
    }
  }

  .menu__overlay {
    display: none;

    @media (max-width: $mobile-size) {
      display: none;
      background-color: $black-60;
      width: 100vw;
      height: 100vh;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
    }

    &.menu__open {
      @media (max-width: $mobile-size) {
        display: block;
      }
    }
  }

  .menu__mobile {
    display: none;

    .burger__trigger {
      display: block;
      padding: 12px;
      appearance: none;
      -webkit-appearance: none;
      border: none;
      outline: none;
      background-color: transparent;
    }

    @media (max-width: $mobile-size) {
      display: block;

      .menu__wrapper {
        position: absolute;
        top: 56px;
        right: -70vw;
        background-color: $navy;
        overflow-y: auto;
        height: calc(100vh - 56px);
        min-width: 70%;
        transition: 0.2s ease all;

        &.menu__open {
          right: 0;
        }

        ul {
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          height: calc(100% - 56px);
          flex-wrap: nowrap;
        }

        a {
          color: 'white';
        }

        .nav-pills .nav-link.active,
        .nav-pills .show > .nav-link {
          background-color: transparent;
        }

        .dropdown-menu {
          position: relative !important;
          float: none !important;
          transform: none !important;
          padding: 0 !important;
          border: none;
          background-color: transparent;
          margin-top: 0;
          margin-left: 16px;

          .dropdown-item {
            padding: 8px 12px;
          }

          button:hover,
          a:hover {
            background-color: unset;
            text-decoration: none;
          }
        }

        .logout__wrapper {
          height: 56px;
          background-color: $navy;

          .btn__logout {
            appearance: none;
            -webkit-appearance: none;
            background-color: transparent;
            color: $white;
            text-align: center;
            width: 100%;
            height: 56px;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

.login__wrapper .PageContent {
  margin-left: 0;
}

.PageContent {
  margin-left: 250px;

  @media (max-width: $mobile-size) {
    margin-left: 0;
    padding-top: 64px;
  }

  & > div {
    padding: 16px 24px;
  }

  .CompanySelect {
    ul {
      margin-left: 0;
      border-radius: 6px;
      box-shadow: 0 4px 8px $black-38;
      padding: 8px 16px;

      li {
        padding: 8px 0;
        border-top: 1px solid $black-12;

        &:first-child {
          border-top: none;
        }

        a:hover {
          text-decoration: none;
        }
      }
    }
  }

  .CompanyHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  h1.Title {
    font-size: 24px;
    color: $dark-grey;
    margin: 5px 0 3px;
  }

  h2.CompanyName {
    font-size: 14px;
    color: $black-60;
  }

  a.addNew {
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 100%;
    display: flex;
    width: 54px;
    height: 54px;
    align-items: center;
    justify-content: center;
    margin: 0 16px 16px 0;
    box-shadow: 4px 4px 8px $black-38;

    .fa-plus {
      font-size: 28px;
    }
  }
}

.JSONEditor .ace_editor {
  width: inherit !important;
  position: relative;
  margin-bottom: 18px;
}

/*   Buttons   */
body .btn-info {
  background-color: $blue;
  border-color: $blue;

  &:hover {
    background-color: $blue;
  }
}

body .btn-outline-info {
  color: $blue;
  border-color: $blue;

  &:hover {
    background-color: $blue;
    border-color: $blue;
  }
}

body .badge-info {
  background-color: $blue;
}

.cursor-pointer {
  cursor: pointer;
}

.no-text-highlight {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.add__btn-squared {
  font-family: $light;
  font-size: 12px;
  line-height: 16px;
  background-color: $btn-green;
  color: $white;
  border-radius: 5px;
  padding: 10px 14px;
  cursor: pointer;
  .add__text {
    font-weight: bold;
  }
}

.add__btn {
  font-family: $light;
  font-size: 12px;
  line-height: 16px;
  background-color: $btn-green;
  color: $white;
  border-radius: 100px;
  padding: 10px 14px;
  cursor: pointer;
  .add__text {
    margin-left: 8px;
  }
}

.btn--blue {
  color: $btn-blue;
}

.btn--red {
  color: $btn-red;
}

.btn--green {
  color: $btn-green;
}

.disabled {
  opacity: 0.25;
}

.btn-hover-circle {
  background: #eee;
  border-radius: 50%;
  box-shadow: 0 0 0 6px #eee;
}

.btn-hover-circle-cancel {
  background: #feebeb;
  border-radius: 50%;
  box-shadow: 0 0 0 6px #feebeb;
}

ul.CompanyConfigEditNav li {
  font-size: 9pt;
}

.HelpSystem {
  cursor: default;
  margin-left: 8px;
}

/*   User Inputs   */
.userInput_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;

  label {
    width: 100%;
  }

  .inputLabel {
    position: absolute;
    top: 0.3em;
    left: 0;
    font-size: 1em;
    line-height: 1.9em;
    padding: 0 12px;
    transition: 0.2s all ease;
  }

  .inputLabel.inputActive {
    font-size: 0.6em;
    top: 0;
  }

  .userInput {
    padding: 0.8em 12px 0.2em;
    width: 100%;
    background-color: $light-grey;
    color: $dark-grey;
    border-radius: 6px 6px 0 0;
    border: none;
    border-bottom: 1px solid $black-60;

    &:focus {
      outline: none;
      border-bottom-color: $blue;
    }

    &:focus ~ span {
      color: $blue;
      font-size: 0.6em;
      top: 0;
    }
  }
}

.userInputBar_wrapper {
  color: $dark-grey;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  .userInputBar__child {
    align-items: center;
    display: flex;
  }
  .userInputBar__inputBox {
    border: #ced4da solid 1px;
    border-radius: 3px;
    padding-right: 4px;
    color: grey;
    input {
      width: 40px;
      -moz-appearance: textfield;
      -webkit-user-select: all;
      -moz-user-select: all;
      -ms-user-select: all;
      user-select: all;
      text-align: center;
      border: none;
      background-color: transparent;
      color: $dark-grey;
      font-size: 1em;
      line-height: 1.9em;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .userInputBar__button {
    background-color: #1871e7;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 1em;
    padding: 0 12px;
    cursor: pointer;
  }
}

label.dealerForm__label {
  font-family: $regular;
  font-size: 14px;
  color: $black-60;
  width: 25%;
  margin-bottom: 0;
}

.dealerForm__input {
  position: relative;
  font-family: $light;
  font-size: 14px;
  color: $black-60;
  padding: 0.35rem 0.8rem;
}

.dealerForm__inputSubmit {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid $blue;
  position: absolute;
  right: 0;
  top: 0;
  font-family: $regular;
  font-size: 14px;
  color: $blue;
  background-color: transparent;
  border-radius: 4px;
  padding: 6px 24px;
  transition: 0.2s all ease;

  &:hover {
    color: $white;
    background-color: $blue;
    border-color: $blue;
  }
}

input[type='checkbox'].dealerForm__checkbox {
  max-width: 14px;
  margin-right: 8px;
}

.dealerForm__chipsList {
  list-style-type: none;
  padding: 0;
  margin-top: 1em;

  li {
    display: inline-block;
    margin: 0 8px 8px 0;

    label.dealerForm__chips {
      margin-bottom: 0;

      input[type='checkbox'] {
        display: none;
      }

      span {
        font-family: $light;
        font-size: 12px;
        color: $black-60;
        background-color: $black-05;
        border-radius: 100px;
        padding: 4px 12px;
        display: inline-block;
        white-space: nowrap;
      }

      & :checked + span {
        color: $blue;
        background-color: $light-blue;
      }
    }
  }
}

/*   Tables   */
.PageContent .table {
  border-radius: 6px;
  box-shadow: 0 4px 8px $black-38;
  border: none;
  overflow: hidden;

  thead {
    tr {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: $black-05;
      border-bottom-width: 0;
      padding-bottom: 2px;

      th {
        font-weight: 300 !important;
        color: $black-60 !important;
        font-size: 0.8em !important;
        width: 20%;
        border: none;

        &:last-child {
          width: 10%;
          padding-right: 24px !important;
          text-align: left !important;
        }
        &.sorting:last-child:hover,
        &.sorting_asc:last-child:hover,
        &.sorting_desc:last-child:hover {
          padding-right: 16px !important;
        }
      }

      th::before {
        display: none !important;
      }

      th.sorting::after,
      th.sorting_asc::after,
      th.sorting_desc::after {
        content: '';
        position: relative;
        display: none;
        margin-left: 0.6em;
        vertical-align: 0.255em;
        border-top: 4px solid $black-60;
        border-right: 4px solid transparent;
        border-bottom: 0;
        border-left: 4px solid transparent;
        top: 2px;
        right: 0;
        transition: 0.2s all ease-in;
      }

      th.sorting_asc::after {
        transform: rotate(180deg);
      }

      th:hover::after {
        display: inline-block;
        opacity: 1 !important;
      }
    }
  }

  thead tr th,
  tbody tr td {
    vertical-align: middle;
    padding: 2px 16px;

    &:last-child {
      text-align: right;
    }
  }

  tbody {
    display: block;
    padding: 0 16px;
    min-width: 100%;

    tr {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: $white !important;
      border-bottom-color: $black-12;
      border-width: 1px;
      td {
        border: none;
        width: 20%;
      }

      td:last-child {
        width: 10%;
        border-bottom-width: 0;
        padding-right: 0;
      }
    }
  }
}

.actionsTable__wrapper {
  background-color: $white;
  border-radius: 4px;
  padding: 8px;
  margin-top: 20px;

  .MuiToolbar-gutters {
    padding: 0 !important;
  }

  @media (max-width: $mobile-size) {
    overflow-x: auto;
  }

  &:first-child {
    margin-top: 0;
  }

  h2 {
    font-family: $regular;
    font-size: 16px;
    color: $dark-grey;
    margin-bottom: 6px;
  }

  h3 {
    /*width: 100%;*/
    font-size: 14px;
    margin: 8px 0 0;
  }

  p {
    font-family: $light;
    font-size: 14px;
    color: $black-38;
  }

  thead {
    background-color: $bg-gray;

    th {
      background-color: transparent;
      font-family: $heavy;
      font-size: 14px;
      padding: 0 8px;
      white-space: nowrap;
      z-index: 1;

      > div {
        min-height: 42px;
        display: flex;
        align-items: center;
      }
    }

    .actionsTable__label {
      font-family: $heavy;
      font-size: 12px;
      padding: 12px;
    }
  }

  .MuiPaper-root {
    box-shadow: none !important;
  }

  .MuiInputBase-root {
    font-family: $light;
  }

  .actionsTable__checkboxTD {
    width: 30px;
    margin: 0 auto;

    //input[type="checkbox"] { /* change "red" browser chrome to blue */
    //  filter: invert(0%) hue-rotate(225deg) brightness(1);
    //}

    .actionsTable__checkboxWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 30px;
      margin: 0 auto;

      .actionsTable__checkbox {
        width: 14px;
        height: 14px;
      }
    }
  }

  .actionsTable__options {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .actionsTable {
    width: 100%;
    display: table;
  }

  .actionsTable__header {
    display: table-row;
    background-color: $bg-gray;
    border-top: 1px solid $black-05;
    border-bottom: 1px solid $black-05;

    .actionsTable__label {
      font-family: $heavy;
      font-size: 12px;
      padding: 12px;
    }
  }

  tbody .MuiTableRow-root,
  tbody .actionsTable__row {
    font-family: $light;
    font-size: 12px;
    overflow-wrap: anywhere;
    // border-bottom: 1px solid $black-05;

    // &:last-child {
    //   border-bottom: 0px solid $black-05;
    // }
    .MuiTableCell-root {
      font-family: $light;
      font-size: 12px;
      padding: 0;
    }

    .MuiTableCell-paddingCheckbox > div {
      justify-content: center;
    }

    .actionsTable__sku {
      font-family: $heavy;
      width: 140px;
    }

    .actionsTable__prodText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc((100vw - 250px) / 8);
      margin: 8px;

      @media (max-width: $mobile-size) {
        max-width: 25vw;
      }
    }

    .actionsTable__thumb {
      width: 138px;
      padding: 8px 0;
      margin: 8px;

      .actionsTable__thumbWrapper {
        width: 138px;
        height: 78px;
        background-color: $black-12;
      }
    }

    .actionsTable__title {
      max-width: calc(25vw - 250px);
      min-width: 100px;
      margin: 8px;

      @media (max-width: $mobile-size) {
        max-width: 25vw;
      }

      &:first-child {
        min-height: 42px;
        display: flex;
        align-items: center;
      }
    }

    .actionsTable__full {
      margin: 8px;

      span {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .actionsTable__description {
      max-width: calc(25vw - 250px);
      margin: 8px;

      @media (max-width: $mobile-size) {
        max-width: 25vw;
      }

      span {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .actionsTable__categories {
      max-width: calc((100vw - 250px) / 5);
      margin: 8px;

      @media (max-width: $mobile-size) {
        max-width: 25vw;
      }

      span {
        display: inline-block;
        margin: 0 4px 4px 0;
        padding: 2px 10px;
        color: $dark-grey;
        background-color: $black-12;
        border-radius: 100px;
      }
    }

    .actionsTable__boolean {
      width: 40px;
      text-align: center;
      margin: 8px;
    }

    .actionsTable__multirow {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .actionsTable__inspect {
      max-width: calc((100vw - 250px) / 5);
      margin: 8px;

      @media (max-width: $mobile-size) {
        max-width: 25vw;
      }

      span {
        display: inline-block;
        margin: 0 4px 4px 0;
        padding: 2px 10px;
        color: $AT-blue;
        background-color: $AT-blue-low;
        border-radius: 100px;
      }
    }

    .actionsTable__presentations,
    .actionsTable__products {
      white-space: normal;
      max-width: calc((100vw - 250px) / 5);
      margin: 8px;

      @media (max-width: $mobile-size) {
        max-width: 25vw;
      }

      span {
        display: inline-block;
        margin: 0 4px 4px 0;
        padding: 2px 10px;
        border-radius: 100px;
        background-color: $btn-green-low;
        color: $btn-green;
      }

      .actionsTable__prodNames span {
        background-color: $btn-orange-low;
        color: $btn-orange;
        white-space: nowrap;
      }
    }

    .actionsTable__details {
      width: 100px;
      margin: 8px;

      & > div {
        display: inline-block;
      }

      span {
        font-family: $regular;
        background-color: $btn-blue;
        color: $white;
        border-radius: 100px;
        padding: 8px 12px 7px;
        display: block;
        white-space: nowrap;
      }
    }

    .actionsTable__actions {
      white-space: nowrap;
      width: 84px;
      margin: 8px;

      div {
        display: inline-block;
      }

      .actionsTable__icon {
        display: inline;
        font-size: 14px;
        appearance: none;
        -webkit-appearance: none;
        border: none;
        outline: none;
        background: none;

        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }

  .actionsTable__prodNames span {
    display: inline-block;
    margin: 0 4px 4px 0;
    padding: 2px 10px;
    border-radius: 100px;
    background-color: $btn-orange-low;
    color: $btn-orange;
    white-space: nowrap;
  }

  .actionsTable__presentations,
  .actionsTable__products {
    white-space: normal;
    max-width: calc((100vw - 250px) / 5);
    margin: 8px;

    @media (max-width: $mobile-size) {
      max-width: 25vw;
    }

    span {
      display: inline-block;
      margin: 0 4px 4px 0;
      padding: 2px 10px;
      border-radius: 100px;
      background-color: $btn-green-low;
      color: $btn-green;
    }

    .actionsTable__prodNames span {
      background-color: $btn-orange-low;
      color: $btn-orange;
      white-space: nowrap;
    }
  }

  .actionsTable__inspect {
    max-width: calc((100vw - 250px) / 5);
    margin: 8px;

    @media (max-width: $mobile-size) {
      max-width: 25vw;
    }

    span {
      display: inline-block;
      margin: 0 4px 4px 0;
      padding: 2px 10px;
      color: $AT-blue;
      background-color: $AT-blue-low;
      border-radius: 100px;
    }
  }

  .table__input {
    padding: 12px;

    textarea,
    input {
      border: 1px solid $black-12;
      border-radius: 4px;
      padding: 6px 12px;
      width: 100%;

      &:focus {
        border: 1px solid $blue;
        outline: none;
      }
    }
  }
}

.actionsTable__wrapper.table__inset {
  .actionsTable__title {
    max-width: none !important;
  }
}

/*    Contracts Page     */
.contractsPage {
  h3 {
    width: auto;
  }

  .head__smaller {
    width: 84px;
  }

  td {
    vertical-align: top;
  }
}

.actionsTable__actions {
  white-space: nowrap;
  width: 84px;
  margin: 8px;

  div {
    display: inline-block;
  }

  .actionsTable__icon {
    display: inline;
    font-size: 14px;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: none;

    &:first-child {
      margin-right: 8px;
    }
  }
}

/*   Search Component   */
.userSearch__wrapper {
  position: relative;
  display: inline-block;

  input.userSearch,
  .userSearch input {
    font-family: $light;
    font-size: 14px;
    line-height: 24px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    border: 1px solid $search-outline;
    border-radius: 0px;
    padding: 6px 10px 7px 38px;

    *:focus {
      border: none;
    }

    &:focus-within {
      border: 1px solid $blue;
    }
  }
  .search__icon {
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 7px 12px;
  }
}

.UsersIndex .table {
  thead {
    tr {
      th {
        &:first-child {
          width: 50%;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        &:first-child {
          width: 50%;
        }
      }
    }
  }
}
/* END Search Component   */

/* <ActionBarOverlay/> */
.actionbar-overlay {
  position: fixed;
  z-index: 9999;
  bottom: 0px;
  right: 0px;
  margin-bottom: 12px;
  margin-right: 24px;
  height: 48px;
  border: 0;
  border-radius: 6px 6px 6px 6px;
  -moz-box-shadow: 0 0 24px rgb(0 0 0 / 0.6);
  -webkit-box-shadow: 0 0 24px rgb(0 0 0 / 0.6);
  box-shadow: 0 0 24px rgb(0 0 0 / 0.6);
  width: -webkit-calc(100% - 298px);
  width: -moz-calc(100% - 298px);
  width: calc(100% - 298px);
  background-color: var(--blue);

  @media (max-width: $mobile-size) {
    width: 95%;
  }
}
.actionbar-content {
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  height: 48px;
  align-items: center;
  color: white;
  font-size: 12px;
}
.actionbar-col1 {
  width: 48px;
  text-align: center;
  float: left;
}
.actionbar-col2 {
  text-align: left;
}
.actionbar-col3 {
  margin-left: auto;
}
.mui-btn-outlined {
  color: $white !important;
  border: 1px solid $white !important;
  margin-left: 8px !important;
  text-transform: none !important;
}
.mui-btn {
  text-transform: none !important;
  margin-right: 8px !important;
  text-decoration: none !important;
}

/* DateRangePicker.js */
.date-range-picker-wrapper {
  display: flex;
  flex-direction: row;
  .date-container {
    flex: 1;
  }
  .date-picker {
    margin-top: 8px;
  }
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.proposal-select-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .advanced-search {
    flex: 2 1 auto;
    margin-right: 3px;
  }
  .date-range-picker {
    flex: 2;
    min-width: 250px;
    margin-right: 3px;
  }
  .personnel-picker {
    flex: 1;
    min-width: 200px;
  }
  .go-button {
    flex: 0;
    margin-right: 3px;
  }
}

/* dropdown menu stuff > 11/2021 */
.menu-padding-left {
  padding-left: 24px;
}
.menu-padding-left-8 {
  padding-left: 8px;
}
.menu-padding-bottom {
  padding-bottom: 8px;
}
.menu-sub-header {
  font-size: 18px;
}

.MuiTablePagination-selectLabel {
  margin: auto;

  @media (max-width: $mobile-size) {
    margin-left: 32px;
  }
}

.MuiTablePagination-displayedRows {
  margin: auto;
}

/* Dialog stuff used in Proposals & Publish Configurations */
.dialog-section-title {
  font-size: 16px;
  font-weight: bold;
}
.dialog-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.dialog-content {
  margin-top: 18px;
}
.dialog-content-fq {
  display: flex;
  margin-top: 12px;
}

.textInputBasic-padded {
  padding-left: 24px;
  border: 5px solid red;
}

.mui-chip-default {
  margin-bottom: 0;
  font-family: $light;
  font-size: 12px;
  color: $black-60;
  background-color: $black-05;
  border-radius: 100px;
  padding: 4px 12px;
  display: inline-block;
  white-space: nowrap;
}
.mui-chip-selected {
  color: $blue !important;
  background-color: $light-blue !important;
}

.about-us {
  display: flex;
  justify-content: space-evenly;
  .half-section {
    width: 50%;
    padding: 12px 24px;
    background-color: #ffffff;
    border-radius: 6px;
    &:not(:last-child) {
      margin-right: 12px;
    }
    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin-bottom: 0;
      }
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
    .section-children {
      margin-top: 12px;
      .ab-highlight {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:not(:last-child) {
          border-bottom: 1px solid #f5f5f5;
          padding-bottom: 12px;
          margin-bottom: 12px;
        }
        .ab-highlight-content {
          display: flex;
          flex-direction: column;
          .ab-highlight-content-title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0;
          }
          .ab-highlight-content-subtitle {
            font-size: 12px;
            margin-bottom: 0;
            color: gray;
            font-weight: normal;
          }
        }
        .ab-highlight-toolbar {
          margin-left: auto;
          display: flex;
          align-items: center;
          .ab-highlight-toolbar-button {
            margin-left: 12px;
          }
        }
      }
      .ab-image {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        &:not(:last-child) {
          border-bottom: 1px solid #f5f5f5;
          padding-bottom: 12px;
          margin-bottom: 12px;
        }
        .ab-image-content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          // space x px between image and text

          .ab-image-content-title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0;
          }
          .ab-image-content-image {
            width: 48px;
            height: 48px;
            background-color: #f2f2f2;
            margin-right: 12px;
            object-fit: cover;
            // fit image to container

            // if unloaded, show a loading icon
          }
        }
        .ab-image-toolbar {
          margin-left: auto;
          display: flex;
          align-items: center;
          .ab-image-toolbar-button {
            margin-left: 12px;
          }
        }
      }
    }
  }
}

fieldset {
  legend {
      width: unset;
  };
}

.loading-please-wait-button {
  position: absolute;
  top: 8;
}
.loading-please-wait-button > img {
  width: 12px !important;
}