
@import './fonts_and_colors.scss';

/** ImageView */
.grid-container {
    border: 1px dotted grey;
}
.grid-sub-container {
    padding: 24px;
}
.grid-item-image-container {
    height: 125px;
}
.grid-item-image {
    max-height: 125px;
    max-width: 100%;
}
.grid-item-info {
    padding-top: 12px;
    overflow-wrap: anywhere;
}
.image-title {
    font-size: 14px;
    font-weight: bolder;
}
.image-title-value {
    font-size: 14px;
}
.table-row {
    border: 1px solid rgba(0,0,0,.05);
}
.breakdown-table-cell-left {
    width: 150px;
    background-color: #f5f5f5;
    vertical-align: top !important;
    border-right: 1px solid rgba(0,0,0,0.09);
}
.breakdown-table-cell-right {
    vertical-align: top !important;
}
.breakdown-section-title {
    font-size: 16px;
    font-weight: bold;
}
.breakdown-container {
    padding-bottom: 12px;
}
.breakdown-title {
    font-size: 16px;
    font-weight: bold;
}
.breakdown-subcontainer {
    padding-bottom: 0px;
}
.breakdown-subtitle {
    font-size: 14px;
    font-weight: bold;
}
.breakdown-completed {
    color: $blue;
    cursor: pointer;
}
.breakdown-info {
    color: $green;
    cursor: pointer;
}
.breakdown-error {
    border: 0px solid red;
    padding: 0px;
}
.breakdown-container ul { 
    margin-block-start: .12em;
    margin-block-end: .12em;
}