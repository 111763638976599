#asset-action :focus {
    outline: 0;
}

.image-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 3px;
}

.asset-search {
    float: right;
}

.row {
    display: block;
}

.selector-tile {
    display: flex;
    height: 136px;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
    margin-bottom: 8px;
}

.selector-title {
    width: 243px;
    height: 45px;
    font-size: 12px;
    padding: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    justify-content: center;
}

.tab-panel-hide {
    display: none !important;
}

.tab-panel-show {
    background-color: red;
    border: 5px solid red;
}

.search-noresult {
    width: 100% !important;
    color: #0000005c;
    font-size: 16px;
}

.search-noresult-span {
    display: table;
    margin: 0 auto;
    line-height: 174px;
    vertical-align: middle;
}

/**
 * editors with asset selector
 */
.div__selected-asset {
    float: left;
    height: 140px;
    width: 249px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
}

.selected-Asset {
    max-width: 249px;
}

.responsive {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid rgba(0,0,0,.0);
  }