:root {
    --blue: #1871e7;
    --white: #FFFFFF;
    --lgrey: #E8E8E8;
    --hover: #e7f0fc;
}
.upload-overlay {
    position: fixed;
    z-index: 9999;
    bottom: 24px;
    right: 24px;
    width: 500px;
    background: rgba(255, 255, 255, 1.0);
    border: 0px;
    border-radius: 6px 6px 6px 6px;
    -moz-box-shadow: 0 0 24px rgb(0 0 0 / 0.6);
    -webkit-box-shadow: 0 0 24px rgb(0 0 0 / 0.6);
    box-shadow: 0 0 24px rgb(0 0 0 / 0.6);
}
.upload-overlay-dropzone {
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 15%;
    left: 10%;
    height: 70%;
    width: 80%;
    background-color:var(--white);
}
.upload-overlay .upload-overlay-content-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.upload-overlay .upload-overlay-top-header {
    display: flex;
    margin: 16px 16px 0px 16px;
    padding: 0;
}
.upload-overlay .upload-overlay-header-title-bar-content {
    flex: 3;
    margin-bottom: 16px;
}
.upload-overlay .upload-overlay-header-title-bar-buttons {
    flex: 1;
}
.upload-overlay .upload-overlay-bottom-header {
    display: flex;
    margin: 0px;
    padding-bottom: 6px;
}
.upload-overlay .upload-overlay-header-percent {
    flex: 1;
    height: 1px;
}
.upload-overlay .upload-overlay-content {
    display: flex;
    flex-direction: column;
    background-color: white;
}
.upload-overlay-content-scroll {
    overflow: auto;
    overflow-y: auto;
    max-height: 50vh;

}
.title-bar-button {
    border: 0;
    padding-left: 12px;
    background-color: white;
    vertical-align: center;
}
.drag-over {
    opacity: 0.5;
    background-color: white;
}
.upload-dropzone-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px 24px 0px 24px;
    background-color: white;
    border: 2px dashed var(--lgrey);
    border-radius: 6px 6px 6px 6px;   
    height: 60vh;
}
.upload-dropzone-container:hover {
    background-color: var(--hover);
    border: 2px dashed blue;
}
.upload-dropzone-sub-container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}
.upload-dropzone-sub-container-text-top-row {
    align-items: flex-start;
    max-width: 100%;
    height: 20vh;
}
.upload-dropzone-sub-container-text-bottom-row {
    align-items: flex-end;
}
.cloud-upload-outlined-icon {
    transform: scale(6.0);
    color: #f2f2f2;
}
.cloud-upload-outlined-icon-hover {
    transform: scale(6.0);
    color: var(--blue);
}
.upload-button {
    border: 0px;
    background-color: white;
    color: var(--blue);
}
.upload-button-hover {
    background-color: var(--hover);
}
.hidden {
    display: none;
}
.progress-circle {
    width: 24px;
    vertical-align: top;
    padding: 2px;
}
.progress-line {
    width: 100%;
    height: 7px;
    border-radius: 0px 0px 0px 0px;
}
.progress-line-minimized {
    width: 100%;
    height: 7px;
    border-radius: 0px 0px 12px 12px;
}
.upload-item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom:1px dotted #DDD;
    margin-right: 16px;
    margin-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 10px;
}
.upload-item-container .upload-item-container-col1 {
    flex: 1;
    flex-grow: 0;
    padding-right: 6px;
}
.upload-item-container .upload-item-container-col2 {
    flex: 5;
    margin: auto;
}
.upload-item-container .upload-item-container-col3 {
    flex: 1;
    flex-grow: 0;
}
.font-xl-b {
    font-size: x-large;
    font-weight: bold;
}
.font-l-b {
    font-size: large;
    font-weight: bold;
}
.font-m-n {
    font-size: medium;
    font-weight: normal;
}
.font-s-b {
    font-size: small;
    font-weight: bold;
}
.font-xs-n {
    font-size: x-small;
    font-weight: normal;
}
.circle {
    flex: 1;
    flex-grow: 0;
    display: block;
    height: 120px;
    width: 120px;
    line-height: 120px;
    -moz-border-radius: 60px; /* or 50% */
    border-radius: 60px; /* or 50% */
    background-color: var(--blue);
    color: white;
    font-size: 2em;
}
.grey {
    color: var(--lgrey);
}
.modal-backdrop {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    background-color: rgba(0,0,0,.38) !important;
}
.flex {
  display: flex;
}
.w-50 {
  width: 50%;
}
.p-4 {
  padding: 4px;
}
.space-x-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.bottom-toolbar {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}