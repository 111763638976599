@font-face {
  font-family: 'Roboto-Light';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto-Medium';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto-Bold';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Roboto-Bold.ttf');
}

$regular: 'Roboto-Regular', sans-serif;
$light: 'Roboto-Light', sans-serif;
$heavy: 'Roboto-Bold', sans-serif;

$black-05: rgba(0, 0, 0, .05);
$black-06: rgba(0, 0, 0, .06);
$black-12: rgba(0, 0, 0, .12);
$black-38: rgba(0, 0, 0, .38);
$black-54: rgba(0, 0, 0, .60);
$black-60: rgba(0, 0, 0, .60);
$black: #000000;
$text-input: #9E9E9E;
$table-border-grey: #DCDBDC;
$blue: #1871E7;
$light-blue: #E7F0FC;
$navy: #2A3042;
$pale-navy: #A6B0CF;
$red: #F47A7A;
$green: #00C134;
$yellow: #F8C61C;
$light-grey: #F2F2F2;
$dark-grey: #303A42;
$background: #FFF;
$bg-gray: #F8F8FB;
$white: #FFF;

$btn-green: #34C38F;
$btn-green-low: rgba(52,195,143,0.18);
$btn-orange: #FF8C3B;
$btn-orange-low: rgba(255,140,59,0.1);
$btn-red: #e00000;
$btn-blue: #556EE6;
$search-outline: #CED4DA;
$AT-blue: #3440c3;
$AT-blue-low: rgba(52,64,195,0.1);

$field-select: $black-06;
$bg-dropdown: $black-06;
$bg-table: $black-06;
$total-red: $red;
$total-green: $green;
$highlight-blue: #E5F0FE;
$mobile-size: 899px;

/* Utility font classes */
.font-xl-b {
  font-size: x-large;
  font-weight: bold;
}
.font-l-b {
  font-size: large;
  font-weight: bold;
}
.font-m-b {
  font-size: medium;
  font-weight: bold;
}
.font-m-n {
  font-size: medium;
  font-weight: normal;
}
.font-s-b {
  font-size: small;
  font-weight: bold;
}
.font-s-n {
  font-size: small;
  font-weight: normal;
}
.font-xs-n {
  font-size: x-small;
  font-weight: normal;
}
.font-xs-b {
  font-size: x-small;
  font-weight: bold;
}
